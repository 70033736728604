import Button from '@/components/atoms/Button/Button';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import { EstimatedCalculator } from '@/components/molecules/EstimatedCalculator';
import {
  CALCULATOR_DISCLAIMER,
  CALCULATOR_DISCLAIMER_LONG,
} from '@/lib/constants';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { CalculatorType } from './types';

interface CalculatorWrapperProps {
  type: CalculatorType;
  mode: 'Finance' | 'Lease';
  inputs: ReactElement;
  breakdown: ReactElement;
  handleOfferButton?: () => void;
  monthlyEstimatedPayment: number;
  handleChangeMonthlyEstimatedPayment: (estimatedPayment: string) => void;
  handleChangeMode: (val: 'Finance' | 'Lease') => void;
}

export const CalculatorWrapper: React.FC<CalculatorWrapperProps> = ({
  type,
  mode,
  handleChangeMode,
  monthlyEstimatedPayment,
  handleChangeMonthlyEstimatedPayment,
  handleOfferButton,
  inputs,
  breakdown,
}) => {
  let estimatedValueTitle = 'Total';
  if (type === 'homepage') estimatedValueTitle = 'Estimated Budget';
  if (type === 'vlp') estimatedValueTitle = 'Payment After Est. Savings';

  return (
    <div
      className={clsx(
        'flex h-full w-full flex-col rounded-b-small rounded-t-small border border-neutral-200 bg-white',
        {
          'l:flex-row': type === 'homepage',
          'ml:flex-row': type === 'vdp',
          'm:flex-row': type === 'vlp',
        }
      )}
    >
      <div
        className={clsx(
          'flex w-full flex-col gap-s rounded-t-small p-l l:rounded-l-small l:rounded-tr-none l:p-xl',
          {
            'l:w-1/2': type === 'homepage',
            'ml:w-1/2 l:w-1/3': type === 'vdp',
            'm:w-1/2': type === 'vlp',
          }
        )}
      >
        {type === 'vdp' && (
          <div className="text-h3SemiBold">Payment Calculator</div>
        )}
        {type !== 'vlp' && (
          <div className="py-l">
            <Switcher
              aria-label="Finance or Lease"
              options={[
                {
                  text: 'Finance',
                  value: 'Finance',
                },
                { text: 'Lease', value: 'Lease' },
              ]}
              value={mode}
              onChange={(val) => {
                handleChangeMode(val as 'Finance' | 'Lease');
              }}
            />
          </div>
        )}
        {inputs}
      </div>
      <div
        className={clsx('flex w-full flex-col rounded-b-small', {
          'l:w-1/2': type === 'homepage',
          'ml:w-1/2 l:w-2/3': type === 'vdp',
          'm:w-1/2': type === 'vlp',
        })}
      >
        <div className="rounded-[0px] bg-neutralsGrey-200 px-xl py-l ml:!rounded-tr-small">
          <EstimatedCalculator
            title={estimatedValueTitle}
            monthlyEstimatedPayment={monthlyEstimatedPayment}
            changeMonthlyEstimatedPayment={handleChangeMonthlyEstimatedPayment}
            editable={type === 'homepage' || type === 'vlp'}
          />
        </div>
        <div className="flex h-full flex-col rounded-b-small bg-white px-xl py-xl l:rounded-bl-[0px] l:rounded-br-small">
          {breakdown}
          <div className="flex h-full flex-col justify-end py-l">
            {type === 'vdp' && handleOfferButton && (
              <>
                <div className="flex w-full">
                  <Button aria-label="Send Offer" onClick={handleOfferButton}>
                    Send Offer
                  </Button>
                </div>

                <span className="pt-l text-microLight text-neutral-800">
                  {CALCULATOR_DISCLAIMER}{' '}
                  <ToolTip
                    aria-label="View discalimer tool tip"
                    hoverElement={
                      <div className="text-microMedium text-blue-medium">
                        View disclaimer
                      </div>
                    }
                  >
                    {CALCULATOR_DISCLAIMER_LONG}
                  </ToolTip>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BreakdownSectionDivider: React.FC<{ strong?: boolean }> = ({
  strong,
}) => {
  return (
    <div
      className={`h-px ${strong ? 'bg-neutral-800' : 'bg-neutral-200'} rounded-small`}
    />
  );
};

export const BreakdownItem: React.FC<{
  label: string;
  tooltip?: string;
  val: string;
}> = ({ label, tooltip, val }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-xs">
        <div className="text-body2Light text-neutral-900">{label}</div>
        {tooltip ? (
          <ToolTip
            aria-label={`${label} tooltip`}
            hoverElement={
              <InformationCircleIcon className="size-l fill-neutral-800" />
            }
          >
            <div>{tooltip}</div>
          </ToolTip>
        ) : null}
      </div>
      <div className="text-body2Light text-neutral-900">{val}</div>
    </div>
  );
};

export const Total: React.FC<{
  label: string;
  monthlyTotal: number;
  overallTotal?: number;
}> = ({ label, monthlyTotal, overallTotal }) => {
  return (
    <div className="flex justify-between">
      <div className="text-body2Medium text-neutral-900">{label}</div>
      <div className="text-body2Light text-neutral-900">
        {overallTotal && `$${Math.round(overallTotal).toLocaleString()} or `}
        <span className="text-body2Medium text-neutral-900">
          ${Math.round(monthlyTotal).toLocaleString()}/mo
        </span>
      </div>
    </div>
  );
};
