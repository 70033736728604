import { Input, InputVariants } from '@/components/atoms/Input/Input';
import { Switcher } from '@/components/atoms/Switcher/Switcher';
import Accordion from '@/components/molecules/Accordion/Accordion';
import { useCalculator } from '@/hooks/paymentCalculator/useFinanceCalculator';
import {
  CALC_FINANCE_LOAN_TERM_OPTIONS,
  INCENTIVES_DISCLAIMER,
} from '@/lib/constants';
import { CalculatorInstanceProps } from './types';
import {
  BreakdownItem,
  BreakdownSectionDivider,
  CalculatorWrapper,
  Total,
} from './Wrappers';

export const FinanceCalculator = ({
  type,
  mode = 'Finance',
  setMode,
  expanded = true,
  setExpanded = () => {},
  salePrice,
  setSalePrice,
  incentivesTotal,
  fuelSavings,
  handleOfferButton,
  onChangeMonthlyEstimatedPayment,
  onChangeTerm,
}: CalculatorInstanceProps) => {
  const {
    downPaymentType,
    downPayment,
    downPaymentPercent,
    downPaymentValid,
    tradeInValue,
    loanOrLeaseTerm: loanTerm,
    aprOrInterestRate: apr,
    monthlyBudget,
    monthlyEstimatedPayment,
    handleChangeMonthlyEstimatedPayment,
    handleChangeSalePrice,
    handleChangeDownPaymentType,
    handleChangeDownPayment,
    handleChangeTradeInValue,
    handleChangeTerm: handleChangeLoanTerm,
    handleChangeAprOrInterestRate: handleChangeApr,
    handleChangeMode,
  } = useCalculator({
    type,
    mode: 'Finance',
    salePrice,
    setSalePrice,
    setMode,
    incentivesTotal,
    fuelSavings,
    onChangeMonthlyEstimatedPayment,
    onChangeTerm,
  });

  const inputs =
    type === 'vlp' ? (
      <div className="flex flex-grow flex-col gap-s">
        <Input
          type={InputVariants.Number}
          aria-label="Listed price"
          value={salePrice.toString()}
          onChange={(val) => handleChangeSalePrice(val)}
          label="Listed price"
          prepend="$"
          placeholder="0"
          step={1000}
        />
        <div>
          <div className="label mb-xs block text-body2Medium text-neutral-800">
            Loan Term
          </div>
          <Switcher
            aria-label="Loan Term"
            options={CALC_FINANCE_LOAN_TERM_OPTIONS.map((option) => ({
              text: option,
              value: option,
            }))}
            value={loanTerm}
            onChange={(e) => handleChangeLoanTerm(e)}
          />
        </div>

        <Accordion
          title={'Customize Payments'}
          buttonClassName="text-blue-medium text-body1Regular"
          className="flex-grow pt-s"
          icon={{ id: 'chevron' }}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <Input
            type={InputVariants.Number}
            aria-label="Est. APR"
            value={apr.toString()}
            onChange={(val) => handleChangeApr(val)}
            label={'Est. APR %'}
            append="%"
            placeholder="8.50"
            step={0.01}
          />
          <Input
            type={InputVariants.Number}
            aria-label="Down Payment"
            value={
              downPaymentType === '$'
                ? downPayment.toString()
                : downPaymentPercent.toString()
            }
            onChange={(val) => handleChangeDownPayment(val)}
            invalid={
              (downPaymentType === '$' && downPayment >= salePrice) ||
              (downPaymentType === '%' &&
                (downPaymentPercent >= 100 || downPaymentPercent < 0))
            }
            label="Down Payment"
            float={0}
            placeholder="0"
            suffixOption={{
              value: downPaymentType,
              options: [
                { label: '$', value: '$' },
                { label: '%', value: '%' },
              ],
              setSuffixOption: (val) =>
                handleChangeDownPaymentType(val as '%' | '$'),
            }}
          />
          {downPaymentValid && (
            <div className="text-microMedium text-red-medium">
              Must be less than sale price
            </div>
          )}

          <Input
            aria-label="Est. Trade-in Value"
            type={InputVariants.Number}
            value={tradeInValue.toString()}
            onChange={(val) => handleChangeTradeInValue(val)}
            invalid={tradeInValue > salePrice}
            label="Est. Trade-in Value"
            prepend="$"
            placeholder="0"
            step={1000}
          />
          {tradeInValue > salePrice && (
            <div className="text-microMedium text-red-medium">
              Must be less than sale price
            </div>
          )}
        </Accordion>
      </div>
    ) : (
      <div className="flex flex-grow flex-col gap-s">
        {setSalePrice ? (
          <Input
            type={InputVariants.Number}
            aria-label="Listed price"
            value={salePrice.toString()}
            onChange={(val) => handleChangeSalePrice(val)}
            label="Listed price"
            prepend="$"
            placeholder="0"
            step={1000}
          />
        ) : (
          <Input
            id="salePrice"
            label="Listed price"
            aria-label="Listed price"
            type={InputVariants.Filled}
            value={`$${salePrice.toLocaleString()}`}
          />
        )}

        <Input
          type={InputVariants.Number}
          aria-label="Est. APR"
          value={apr.toString()}
          onChange={(val) => handleChangeApr(val)}
          label={'Est. APR %'}
          append="%"
          placeholder="8.50"
          step={0.01}
        />
        <Input
          type={InputVariants.Number}
          aria-label="Down Payment"
          value={
            downPaymentType === '$'
              ? downPayment.toString()
              : downPaymentPercent.toString()
          }
          onChange={(val) => handleChangeDownPayment(val)}
          invalid={downPaymentValid}
          label="Down Payment"
          float={0}
          placeholder="0"
          suffixOption={{
            value: downPaymentType,
            options: [
              { label: '$', value: '$' },
              { label: '%', value: '%' },
            ],
            setSuffixOption: (val) =>
              handleChangeDownPaymentType(val as '%' | '$'),
          }}
        />
        {downPaymentValid && (
          <div className="text-microMedium text-red-medium">
            Must be less than sale price
          </div>
        )}

        <Input
          aria-label="Est. Trade-in Value"
          type={InputVariants.Number}
          value={tradeInValue.toString()}
          onChange={(val) => handleChangeTradeInValue(val)}
          invalid={tradeInValue > salePrice}
          label="Est. Trade-in Value"
          prepend="$"
          placeholder="0"
          step={1000}
        />
        {tradeInValue > salePrice && (
          <div className="text-microMedium text-red-medium">
            Must be less than sale price
          </div>
        )}

        <div>
          <div className="label mb-xs block text-body2Medium text-neutral-800">
            Loan Term
          </div>
          <Switcher
            aria-label="Loan Term"
            options={CALC_FINANCE_LOAN_TERM_OPTIONS.map((option) => ({
              text: option,
              value: option,
            }))}
            value={loanTerm}
            onChange={(e) => handleChangeLoanTerm(e)}
          />
        </div>
      </div>
    );
  const inputsResponsiveWrapper =
    type === 'vlp' ? (
      inputs
    ) : (
      <>
        <div className="flex m:hidden">
          <Accordion
            title={'Customize Payments'}
            buttonClassName="text-neutral-900"
            className="flex-grow"
            icon={{ id: 'chevron' }}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            {inputs}
          </Accordion>
        </div>
        <div className="hidden m:flex">{inputs}</div>
      </>
    );

  const breakdown = (
    <div className="flex flex-col gap-s">
      <div className="flex flex-col gap-xs">
        <BreakdownItem
          label="Listed price"
          val={`$${salePrice.toLocaleString()}`}
        />
        <BreakdownItem
          label="Monthly budget"
          val={`$${monthlyBudget.toFixed(0).toLocaleString()}/mo`}
        />
      </div>

      <BreakdownSectionDivider />

      <BreakdownItem
        label="Down Payment"
        val={`${downPayment > 0 ? '-' : ''}$${downPayment.toLocaleString()}`}
      />
      <BreakdownItem
        label="Est. Trade-In Value"
        val={`${tradeInValue > 0 ? '-' : ''}$${tradeInValue.toLocaleString()}`}
      />

      {incentivesTotal || fuelSavings ? <BreakdownSectionDivider /> : null}

      {incentivesTotal ? (
        <BreakdownItem
          label="Incentives and Rebates"
          val={`${incentivesTotal > 0 ? '-' : ''}$${
            incentivesTotal > 0 ? incentivesTotal.toLocaleString() : 0
          }`}
          tooltip={INCENTIVES_DISCLAIMER}
        />
      ) : null}

      {fuelSavings ? (
        <BreakdownItem
          label={`Gas Savings over ${Number(loanTerm) / 12} years`}
          val={`-$${(
            (Number(fuelSavings.fuelSavingsEstimate) *
              (Number(loanTerm) / 12)) /
            Number(fuelSavings.timePeriod)
          ).toLocaleString()}`}
          tooltip={`Efficiency assessments are guided by EPA standards, factoring in 45% highway and 55% city driving for ${Intl.NumberFormat('en-US').format(Number(fuelSavings.annualMiles))} annual miles. Costs are based on national averages: $${Number(fuelSavings.gasPerGallon).toFixed(2)} per gallon of gasoline and $${Number(fuelSavings.electricityPerKwh).toFixed(2)} per kWh for electricity. Actual savings may vary due to market changes and driving conditions. Consumption ratings are provided for both electric vehicles (in kWh/100mi) and gasoline vehicles (in MPG).`}
        />
      ) : null}

      <BreakdownSectionDivider strong={true} />

      <Total
        label="Total"
        monthlyTotal={monthlyEstimatedPayment}
        overallTotal={monthlyEstimatedPayment * Number(loanTerm)}
      />
    </div>
  );

  return (
    <CalculatorWrapper
      type={type}
      mode={mode}
      inputs={inputsResponsiveWrapper}
      breakdown={breakdown}
      monthlyEstimatedPayment={monthlyEstimatedPayment}
      handleChangeMonthlyEstimatedPayment={handleChangeMonthlyEstimatedPayment}
      handleChangeMode={handleChangeMode}
      handleOfferButton={handleOfferButton}
    />
  );
};
