import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import {
  CALCULATOR_DISCLAIMER,
  CALCULATOR_DISCLAIMER_LONG,
} from '@/lib/constants';
import { useState } from 'react';
import { FinanceCalculator } from './FinanceCalculator';
import { LeaseCalculator } from './LeaseCalculator';
import { PaymentCalculatorProps } from './types';

export const PaymentCalculator = (props: PaymentCalculatorProps) => {
  const [mode, setMode] = useState<'Finance' | 'Lease'>('Finance');
  return (
    <div
      className={`flex h-full w-full flex-col rounded-small border border-neutral-200 ${props.type === 'homepage' ? 'rounded-small shadow-xl' : ''}`}
    >
      <div className="flex h-full w-full">
        {mode === 'Finance' ? (
          <FinanceCalculator
            {...props}
            mode={mode}
            setMode={setMode}
            expanded={props.expanded ?? true}
            setExpanded={props.setExpanded}
          />
        ) : (
          <LeaseCalculator
            {...props}
            mode={mode}
            setMode={setMode}
            expanded={props.expanded ?? true}
            setExpanded={props.setExpanded}
          />
        )}
      </div>
      {props.type === 'vlp' && (
        <div className="h-content flex w-full flex-col rounded-b-small border-t border-neutral-200 bg-white">
          <div className="flex w-full gap-s p-xl pb-xl pt-l">
            <div className="text-microLight text-neutral-800">
              {CALCULATOR_DISCLAIMER}
            </div>
            <ToolTip
              aria-label="View disclaimer tool tip"
              hoverElement={
                <div className="text-microMedium text-blue-medium">
                  View disclaimer
                </div>
              }
            >
              {CALCULATOR_DISCLAIMER_LONG}
            </ToolTip>
          </div>
          {props.handleSearch && (
            <div className="flex w-full p-3xl py-xl">
              <Button
                aria-label="Search your budget"
                variant={ButtonVariants.Tertiary}
                onClick={props.handleSearch}
              >
                Search your budget
              </Button>
            </div>
          )}
        </div>
      )}
      {props.type === 'homepage' && (
        <div className="h-content flex w-full rounded-b-small border-t border-neutral-200 bg-white">
          <div className="flex w-full p-3xl py-xl">
            <Button
              aria-label="Search your budget"
              variant={ButtonVariants.Tertiary}
              onClick={() => {
                if (props.handleSearch) {
                  props.handleSearch();
                } else {
                  window.location.href = `/search/price_end/${Math.max(
                    0,
                    props.salePrice
                  )}/sort/year_desc`;
                }
              }}
            >
              Search your budget
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
