import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import { calculatedMonthlyPaymentFinance } from '@/lib/calculatedMonthlyPayment';
import {
  CALC_FINANCE_INITIAL_APR,
  CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT,
  CALC_FINANCE_LOAN_TERM_OPTIONS,
  CALC_INITIAL_FINANCE_TERM_INDEX,
  PRICE_COMING_SOON,
} from '@/lib/constants';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/24/solid';

interface ProductListingLinkPriceProps {
  isListedPrice: boolean;
  price: number | null;
  rebateAmount: number;
  monetaryIncentiveAmount: number;
  totalEstFuelSavingsPerYear: number;
  displayIncentivesAmount: boolean;
  displayRebatesAmount: boolean;
  displayFuelSavingsAmount: boolean;
}

export const ProductListingLinkPrice: React.FC<
  ProductListingLinkPriceProps
> = ({
  isListedPrice,
  price,
  rebateAmount,
  monetaryIncentiveAmount,
  totalEstFuelSavingsPerYear,
  displayIncentivesAmount,
  displayRebatesAmount,
  displayFuelSavingsAmount,
}) => {
  if (!price) {
    return (
      <>
        <section className="flex flex-col gap-xs">
          <div className="flex items-end gap-s">
            <p className="text-h4SemiBold text-neutral-900">
              {PRICE_COMING_SOON}
            </p>
          </div>
        </section>
      </>
    );
  }

  const loanTermMonths = Number(
    CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
  );
  const loanTermYears = loanTermMonths / 12;

  const maxIncentives = monetaryIncentiveAmount + rebateAmount;
  const appliedIncentives =
    (displayIncentivesAmount ? monetaryIncentiveAmount : 0) +
    (displayRebatesAmount ? rebateAmount : 0);
  const unAppliedIncentives = maxIncentives - appliedIncentives;

  let unAppliedIncentivesText = '';
  let appliedIncentivesText = 'Max. Incentives & Rebates';
  let disclaimerText = ['The displayed price excludes incentives and rebates.'];

  if (displayIncentivesAmount && !displayRebatesAmount) {
    unAppliedIncentivesText = `Max. Rebates $${unAppliedIncentives.toLocaleString()}`;
    appliedIncentivesText = `Max. Incentives`;
    disclaimerText = [
      'The displayed price includes the maximum estimated incentives.',
    ];
  } else if (!displayIncentivesAmount && displayRebatesAmount) {
    unAppliedIncentivesText = `Max. Incentives $${unAppliedIncentives.toLocaleString()}`;
    appliedIncentivesText = `Max. Rebates`;
    disclaimerText = [
      'The displayed price includes the maximum estimated rebates.',
    ];
  } else if (!displayIncentivesAmount && !displayRebatesAmount) {
    unAppliedIncentivesText = `Max. Incentives & Rebates $${unAppliedIncentives.toLocaleString()}`;
    appliedIncentivesText = ``;
    disclaimerText = [
      'The displayed price includes the maximum estimated incentives and rebates.',
    ];
  }
  appliedIncentivesText = appliedIncentives > 0 ? appliedIncentivesText : '';
  unAppliedIncentivesText =
    unAppliedIncentives > 0 ? unAppliedIncentivesText : '';

  if (displayFuelSavingsAmount) {
    disclaimerText.push(
      'The displayed price includes fuel savings estimates on this EV compared to the gasoline equivalent car.'
    );
  } else {
    disclaimerText.push(
      'The displayed price excludes fuel savings estimates on this EV compared to the gasoline equivalent car.'
    );
  }
  const fuelSavingsTotal = totalEstFuelSavingsPerYear * loanTermYears;

  const listPrice =
    price -
    appliedIncentives -
    (displayFuelSavingsAmount ? fuelSavingsTotal : 0);

  const fuelSavings = displayFuelSavingsAmount
    ? {
        fuelSavingsEstimate: totalEstFuelSavingsPerYear,
        timePeriod: 1,
      }
    : undefined;

  const monthlyPayment = Math.round(
    calculatedMonthlyPaymentFinance(
      CALC_FINANCE_INITIAL_APR,
      price -
        appliedIncentives -
        price * CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT,
      loanTermMonths,
      fuelSavings
    )
  );

  return (
    <>
      <section className="flex flex-col gap-xs">
        <div className="flex items-end gap-s">
          <p className="text-h4SemiBold text-neutral-900">
            {isListedPrice
              ? `$${listPrice.toLocaleString()}`
              : `$${monthlyPayment.toLocaleString()}`}
            {!isListedPrice && <span className="text-body1Regular">/mo</span>}
          </p>
          <ToolTip
            aria-label="Price savings disclosure tooltip"
            hoverElement={
              <InformationCircleIcon className="mb-0.5 h-[16px] w-[16px] text-neutral-600" />
            }
          >
            <div>
              {disclaimerText.map((text, index) => (
                <p key={`vlp-price-disclaimer-${index}`}>{text}</p>
              ))}
              <p>
                All prices exclude taxes and fees. Prices and estimates are
                subject to change.
              </p>
            </div>
          </ToolTip>
        </div>

        {appliedIncentivesText && (
          <div className="flex items-center gap-xs text-microLight text-blue-medium">
            <CheckIcon className="h-[12px] w-[12px] text-blue-medium" />
            <div>{appliedIncentivesText} </div>
            <div className="text-microMedium">
              -$
              {Math.round(Number(appliedIncentives)).toLocaleString()}
            </div>
          </div>
        )}
        {unAppliedIncentivesText && (
          <div className="flex items-center gap-xs text-microLight text-neutral-800">
            <div>{unAppliedIncentivesText} </div>
          </div>
        )}

        {fuelSavingsTotal !== 0 && (
          <div
            className={`flex items-center gap-xs text-microLight ${displayFuelSavingsAmount ? 'text-green-dark' : 'text-neutral-800'}`}
          >
            {displayFuelSavingsAmount && (
              <CheckIcon className="h-[12px] w-[12px] text-green-dark" />
            )}
            <div>Est. Gas savings </div>
            <div>
              <span
                className={`${displayFuelSavingsAmount ? 'text-microMedium' : ''}`}
              >
                {displayFuelSavingsAmount && '-'}$
                {Math.round(Number(fuelSavingsTotal)).toLocaleString()}
              </span>
              <span>/year</span>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
