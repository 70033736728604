import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import ClientPortal from '@/components/molecules/ClientPortal';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { formatPhoneNumber } from '@/lib/formatPhoneNumber';
import { DealerType } from '@/lib/schema/dealer/types';
import { PhoneIcon } from '@heroicons/react/24/solid';

interface CallModalProps {
  open: boolean;
  onClose: () => void;
  productId: string;
  dealer: DealerType;
}

export const CallModal: React.FC<CallModalProps> = ({
  open,
  onClose,
  dealer,
  productId,
}) => {
  const { handleActionTracking } = useAnalytics();

  return (
    <ClientPortal
      className="relative z-50 w-4/5 max-w-[640px] rounded-small bg-white"
      open={open}
      closeFn={onClose}
    >
      <div className="flex flex-col gap-l p-xl">
        {dealer.name && dealer.city && dealer.province ? (
          <div className="text-body2Light">
            Call: {dealer.name}, {dealer.city}, {dealer.province}
          </div>
        ) : (
          <div className="text-body2Light">No dealer info found</div>
        )}
        {dealer.phone && (
          <div className="flex items-center gap-s">
            <PhoneIcon width={20} height={20} fill="#000000" />
            <a
              href={`tel: ${dealer.phone}`}
              onClick={() => {
                handleActionTracking({
                  ...GOOGLE_TRACK_INFO.callDealerButton,
                  pageTarget: dealer?.phone
                    ? `/vehicle/${productId}?dealerPhone=${dealer.phone}`
                    : `/vehicle/${productId}`,
                });
              }}
            >
              <span className="text-body1Medium text-blue-medium hover:text-blue-dark hover:underline">
                {formatPhoneNumber(dealer.phone)}
              </span>
            </a>
          </div>
        )}
      </div>
    </ClientPortal>
  );
};
