import { IconIds } from '@/components/atoms/legacy/Icon';

export const convertMakeToIconID = (
  make: string
): keyof typeof IconIds | null => {
  if (make === 'Mercedes-Benz') {
    return `Make_MercedesBenz`;
  }
  if (make === 'Rolls-Royce') {
    return `Make_RollsRoyce`;
  }

  const whiteSpaceRemovedMakeName = make.replaceAll(' ', '');

  const iconIdKey = `Make_${whiteSpaceRemovedMakeName}` as keyof typeof IconIds;
  if (iconIdKey in IconIds) {
    return iconIdKey;
  }
  return null;
};
